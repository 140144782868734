import partnerImg1 from "../../assests/partners/PARTENERS-1.png";
import partnerImg2 from "../../assests/partners/PARTENERS-2.png";
import partnerImg3 from "../../assests/partners/PARTENERS-3.png";
import partnerImg4 from "../../assests/partners/PARTENERS-4.png";
import partnerImg5 from "../../assests/partners/PARTENERS-5.png";

export const partnersData = [
  { name: "Ahmed", img: partnerImg1 },
  { name: "Ahmed", img: partnerImg2 },
  { name: "Ahmed", img: partnerImg3 },
  { name: "Ahmed", img: partnerImg4 },
  { name: "Ahmed", img: partnerImg5 },
];
